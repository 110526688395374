import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';

interface PlaybackSpeedControlProps {
  speed: number;
  onSpeedChange: (speed: number) => void;
}

const PlaybackSpeedControl: React.FC<PlaybackSpeedControlProps> = ({ speed, onSpeedChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  // Updated speeds array
  const speeds = Array.from({ length: 16 }, (_, i) => (i * 0.1 + 0.5).toFixed(1));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSpeedChange = (newSpeed: number) => {
    onSpeedChange(newSpeed);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={menuRef}>
      <motion.button
        className="
          relative px-4 py-1.5 text-xs font-medium
          transition-all duration-300 ease-in-out
          bg-white/5 hover:bg-white/10
          text-white/90
          focus:outline-none focus:ring-2 focus:ring-white/30
          group
          font-quicksand lowercase
        "
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="true"
        aria-expanded={isOpen}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <span className="relative z-10">{speed.toFixed(1)}x</span>
        <span
          className="
            absolute inset-0
            bg-gradient-to-r from-gray-400/5 via-gray-500/5 to-gray-900/5
            group-hover:from-gray-400/10 group-hover:via-gray-500/10 group-hover:to-gray-900/10
            transition-opacity duration-300 ease-in-out
          "
        />
      </motion.button>
      {isOpen && (
        <div className="absolute bottom-full left-0 mb-2 bg-black/50 rounded-none shadow-lg border border-white/20 max-h-60 overflow-y-auto">
          {speeds.map((s) => (
            <motion.button
              key={s}
              className={`
                block w-full px-4 py-2 text-left text-xs
                ${parseFloat(s) === speed ? 'text-blue-400' : 'text-white/90'}
                hover:bg-white/10 transition-colors
                font-quicksand lowercase
              `}
              onClick={() => handleSpeedChange(parseFloat(s))}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {s}x
            </motion.button>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlaybackSpeedControl;