import React from 'react';
import { motion } from 'framer-motion';

interface VideoSwitchProps {
  isFullEpisode: boolean;
  onToggle: () => void;
}

const VideoSwitch: React.FC<VideoSwitchProps> = ({ isFullEpisode, onToggle }) => {
  return (
    <motion.button
      onClick={onToggle}
      className="
        relative px-4 py-1.5 text-xs font-medium
        transition-all duration-300 ease-in-out
        bg-white/5 hover:bg-white/10
        text-white/90
        focus:outline-none focus:ring-2 focus:ring-white/30
        group
        font-quicksand lowercase
      "
      aria-label={isFullEpisode ? "Switch to clip" : "Switch to full episode"}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <span className="relative z-10">
        {isFullEpisode ? 'full episode' : 'clip'}
      </span>
      <span
        className="
          absolute inset-0
          bg-gradient-to-r from-gray-400/5 via-gray-500/5 to-gray-900/5
          group-hover:from-gray-400/10 group-hover:via-gray-500/10 group-hover:to-gray-900/10
          transition-opacity duration-300 ease-in-out
        "
      />
    </motion.button>
  );
};

export default VideoSwitch;