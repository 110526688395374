import React, { useState, useRef, useCallback, useEffect } from 'react';
import Hls from 'hls.js';
import Controls from './Controls';
import VolumeControl from './VolumeControl';
import PlaybackSpeedControl from './PlaybackSpeedControl';
import FullscreenButton from './FullscreenButton';
import VideoSwitch from './VideoSwitch';
import TimeDisplay from './TimeDisplay';
import ProgressBar from './ProgressBar';
import { useMediaQuery } from 'react-responsive';
import MobileControls from './MobileControls';
import HotkeysHandler from './HotkeysHandler';

interface VideoPlayerManagerProps {
  url: string;
  start: number;
  end: number;
  isFullEpisode: boolean;
  onToggleFullEpisode: () => void;
  clipDuration: number;
  podcastName: string;
  episodeName: string;
}

const VideoPlayerManager: React.FC<VideoPlayerManagerProps> = ({
  url,
  start,
  end,
  isFullEpisode,
  onToggleFullEpisode,
  clipDuration,
  podcastName,
  episodeName
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [fullDuration, setFullDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isControlsVisible, setIsControlsVisible] = useState(true);
  const controlsTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const hlsRef = useRef<Hls | null>(null);

  const initHls = useCallback(() => {
    if (Hls.isSupported() && videoRef.current) {
      hlsRef.current = new Hls();
      hlsRef.current.loadSource(url);
      hlsRef.current.attachMedia(videoRef.current);
      hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
        setIsLoaded(true);
        if (videoRef.current) {
          videoRef.current.currentTime = start;
        }
        // Removed isPlaying check here
      });
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = url;
      videoRef.current.addEventListener('loadedmetadata', () => {
        setIsLoaded(true);
        if (videoRef.current) {
          videoRef.current.currentTime = start;
        }
        // Removed isPlaying check here
      });
    }
  }, [url, start]); // Removed isPlaying from dependencies

  useEffect(() => {
    initHls();
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [initHls]);

  const handlePlayPause = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);

  const handleRewind = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = Math.max(videoRef.current.currentTime - 10, isFullEpisode ? 0 : start);
    }
  }, [isFullEpisode, start]);

  const handleForward = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = Math.min(videoRef.current.currentTime + 10, isFullEpisode ? fullDuration : end);
    }
  }, [isFullEpisode, fullDuration, end]);

  const handleVolumeChange = useCallback((newVolume: number) => {
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  }, []);

  const handleSpeedChange = useCallback((newSpeed: number) => {
    setPlaybackSpeed(newSpeed);
    if (videoRef.current) {
      videoRef.current.playbackRate = newSpeed;
    }
  }, []);

  const handleSeek = useCallback(
    (seekTime: number) => {
      if (videoRef.current) {
        const actualSeekTime = isFullEpisode
          ? seekTime
          : Math.min(Math.max(seekTime + start, start), end);
        videoRef.current.currentTime = actualSeekTime;
        setCurrentTime(isFullEpisode ? actualSeekTime : seekTime);
      }
    },
    [isFullEpisode, start, end]
  );

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      const videoCurrentTime = videoRef.current.currentTime;
      setCurrentTime(isFullEpisode ? videoCurrentTime : videoCurrentTime - start);
      if (!isFullEpisode && videoCurrentTime >= end) {
        videoRef.current.pause();
        videoRef.current.currentTime = start;
        setIsPlaying(false);
      }
    }
  }, [isFullEpisode, start, end]);

  const handleDurationChange = useCallback(() => {
    if (videoRef.current) {
      setFullDuration(videoRef.current.duration);
    }
  }, []);

  const handleEnded = useCallback(() => {
    setIsPlaying(false);
    if (videoRef.current) {
      videoRef.current.currentTime = isFullEpisode ? 0 : start;
    }
  }, [isFullEpisode, start]);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement && containerRef.current) {
      containerRef.current.requestFullscreen();
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play().catch(error => console.error('Error playing video:', error));
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (videoRef.current && isLoaded) {
      // Always start from the 'start' timestamp, even in full episode mode
      videoRef.current.currentTime = start;
      setCurrentTime(isFullEpisode ? start : 0);
    }
  }, [isFullEpisode, start, isLoaded]);

  const showControls = useCallback(() => {
    setIsControlsVisible(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      setIsControlsVisible(false);
    }, 3000); // Hide controls after 3 seconds of inactivity
  }, []);

  const handleMouseMove = useCallback(() => {
    showControls();
  }, [showControls]);

  const handleMouseLeave = useCallback(() => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    setIsControlsVisible(false);
  }, []);

  useEffect(() => {
    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, []);

  const handleVideoClick = useCallback(() => {
    handlePlayPause();
  }, [handlePlayPause]);

  // Add this new state
  const [isMobileView, setIsMobileView] = useState(false);

  // Add this media query hook
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Add this effect to update the mobile view state
  useEffect(() => {
    setIsMobileView(isMobile);
  }, [isMobile]);

  // Include HotkeysHandler callback functions
  const handleVolumeUp = useCallback(() => {
    handleVolumeChange(Math.min(volume + 0.1, 1));
  }, [volume, handleVolumeChange]);

  const handleVolumeDown = useCallback(() => {
    handleVolumeChange(Math.max(volume - 0.1, 0));
  }, [volume, handleVolumeChange]);

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      const handleMouseEnter = () => {
        currentContainer.focus();
      };
      currentContainer.addEventListener('mouseenter', handleMouseEnter);

      return () => {
        currentContainer.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative w-full h-0 pb-[56.25%]"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      tabIndex={0} // Make container focusable
    >
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full"
        onTimeUpdate={handleTimeUpdate}
        onDurationChange={handleDurationChange}
        onEnded={handleEnded}
        onClick={handleVideoClick}
      />
      <HotkeysHandler
        onPlayPause={handlePlayPause}
        onRewind={handleRewind}
        onForward={handleForward}
        onVolumeUp={handleVolumeUp}
        onVolumeDown={handleVolumeDown}
        onToggleFullscreen={toggleFullscreen}
        containerRef={containerRef} // Pass the container ref
      />
      <div
        className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-30 backdrop-blur-md p-2 transition-opacity duration-300 ${
          isControlsVisible ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <ProgressBar
          currentTime={currentTime}
          duration={isFullEpisode ? fullDuration : clipDuration}
          onSeek={handleSeek}
        />
        {isMobileView ? (
          <MobileControls
            isPlaying={isPlaying}
            onPlayPause={handlePlayPause}
            onRewind={handleRewind}
            onForward={handleForward}
            volume={volume}
            onVolumeChange={handleVolumeChange}
            speed={playbackSpeed}
            onSpeedChange={handleSpeedChange}
            isFullEpisode={isFullEpisode}
            onToggle={onToggleFullEpisode}
            currentTime={currentTime}
            duration={isFullEpisode ? fullDuration : clipDuration}
            onFullscreen={toggleFullscreen}
          />
        ) : (
          <div className="flex items-center justify-between mt-1 relative">
            <VolumeControl volume={volume} onVolumeChange={handleVolumeChange} />
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <Controls
                isPlaying={isPlaying}
                onPlayPause={handlePlayPause}
                onRewind={handleRewind}
                onForward={handleForward}
              />
            </div>
            <div className="flex items-center space-x-2">
              <PlaybackSpeedControl speed={playbackSpeed} onSpeedChange={handleSpeedChange} />
              <VideoSwitch isFullEpisode={isFullEpisode} onToggle={onToggleFullEpisode} />
              <TimeDisplay
                currentTime={currentTime}
                duration={isFullEpisode ? fullDuration : clipDuration}
              />
              <FullscreenButton onClick={toggleFullscreen} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPlayerManager;