import React, { useState, useRef, useEffect, useCallback } from 'react';
import Image from 'next/image';

interface VolumeControlProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}

const VolumeControl: React.FC<VolumeControlProps> = ({ volume, onVolumeChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<number | null>(null);
  const showTimeoutRef = useRef<number | null>(null);

  const startHideTimer = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setIsVisible(false);
    }, 2000); // Hide after 2 seconds
  }, []);

  const handleVolumeChange = useCallback(
    (clientX: number) => {
      if (sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        const newVolume = (clientX - rect.left) / rect.width;
        onVolumeChange(Math.max(0, Math.min(1, newVolume)));
      }
    },
    [onVolumeChange]
  );

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (isDragging) {
        handleVolumeChange(e.clientX);
      }
    },
    [isDragging, handleVolumeChange]
  );

  const handlePointerUp = useCallback(() => {
    setIsDragging(false);
    document.removeEventListener('pointermove', handlePointerMove);
    document.removeEventListener('pointerup', handlePointerUp);
    startHideTimer();
  }, [handlePointerMove, startHideTimer]);

  const handlePointerDown = (e: React.PointerEvent) => {
    setIsDragging(true);
    handleVolumeChange(e.clientX);
    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);
    e.preventDefault();
  };

  const handleMouseEnter = () => {
    if (showTimeoutRef.current !== null) {
      clearTimeout(showTimeoutRef.current);
    }
    showTimeoutRef.current = window.setTimeout(() => {
      setIsVisible(true);
    }, 1000); // Show after 1 second hover
  };

  const handleMouseLeave = () => {
    if (showTimeoutRef.current !== null) {
      clearTimeout(showTimeoutRef.current);
    }
    if (!isDragging) {
      startHideTimer();
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      if (showTimeoutRef.current !== null) {
        clearTimeout(showTimeoutRef.current);
      }
    };
  }, [handlePointerMove, handlePointerUp]);

  const getVolumeIcon = () => {
    return volume === 0 ? (
      <Image src="/Mute On.png" alt="Mute On" width={24} height={24} />
    ) : (
      <Image src="/Mute Off.png" alt="Mute Off" width={24} height={24} />
    );
  };

  return (
    <div
      className="relative flex items-center group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className="p-1 hover:bg-white/10 transition-colors duration-200"
        onClick={() => {
          onVolumeChange(volume === 0 ? 1 : 0);
          setIsVisible(true);
          startHideTimer();
        }}
        aria-label={volume === 0 ? 'Unmute' : 'Mute'}
      >
        {getVolumeIcon()}
      </button>
      <div
        className={`absolute left-full top-1/2 -translate-y-1/2 ml-1 transition-all duration-500 ease-in-out ${
          isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'
        }`}
      >
        <div
          ref={sliderRef}
          className="w-24 h-2 bg-white/20 cursor-pointer relative"
          onPointerDown={handlePointerDown}
        >
          <div
            className="absolute top-0 bottom-0 left-0 bg-white transition-all duration-200"
            style={{ width: `${volume * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default VolumeControl;