import React, { useEffect, useCallback } from 'react';

interface HotkeysHandlerProps {
  onPlayPause: () => void;
  onRewind: () => void;
  onForward: () => void;
  onVolumeUp: () => void;
  onVolumeDown: () => void;
  onToggleFullscreen: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

const HotkeysHandler: React.FC<HotkeysHandlerProps> = ({
  onPlayPause,
  onRewind,
  onForward,
  onVolumeUp,
  onVolumeDown,
  onToggleFullscreen,
  containerRef,
}) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // Ignore key presses if focus is on an input or textarea
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      switch (event.key.toLowerCase()) {
        case ' ':
        case 'k':
          event.preventDefault();
          onPlayPause();
          break;
        case 'arrowleft':
        case 'j':
          onRewind();
          break;
        case 'arrowright':
        case 'l':
          onForward();
          break;
        case 'arrowup':
          event.preventDefault();
          onVolumeUp();
          break;
        case 'arrowdown':
          event.preventDefault();
          onVolumeDown();
          break;
        case 'f':
          onToggleFullscreen();
          break;
        case 'm':
          event.preventDefault();
          // Add mute functionality if needed
          break;
        default:
          break;
      }
    },
    [onPlayPause, onRewind, onForward, onVolumeUp, onVolumeDown, onToggleFullscreen]
  );

  useEffect(() => {
    const currentContainer = containerRef.current;

    if (currentContainer) {
      currentContainer.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [handleKeyPress, containerRef]);

  return null; // This component doesn't render anything
};

export default HotkeysHandler;