import React, { useState, useRef, useCallback } from 'react';

interface ProgressBarProps {
  currentTime: number;
  duration: number;
  onSeek: (time: number) => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentTime, duration, onSeek }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const [isScrubbing, setIsScrubbing] = useState(false);

  const handleMouseDown = useCallback(() => {
    setIsScrubbing(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsScrubbing(false);
  }, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (progressBarRef.current && isScrubbing) {
        const rect = progressBarRef.current.getBoundingClientRect();
        const position = (event.clientX - rect.left) / rect.width;
        onSeek(position * duration);
      }
    },
    [duration, isScrubbing, onSeek]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (progressBarRef.current) {
        const rect = progressBarRef.current.getBoundingClientRect();
        const clickPosition = (event.clientX - rect.left) / rect.width;
        onSeek(clickPosition * duration);
      }
    },
    [duration, onSeek]
  );

  // Ensure the progress width never exceeds 100%
  const progressWidth = `${Math.min((currentTime / duration) * 100, 100)}%`;

  return (
    <div
      className="relative w-full h-1 bg-gray-600 cursor-pointer"
      ref={progressBarRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <div
        className="absolute top-0 left-0 h-full bg-white"
        style={{ width: progressWidth }}
      />
      <div
        className="absolute top-0 h-full w-1 bg-white"
        style={{ left: progressWidth }}
      />
    </div>
  );
};

export default ProgressBar;