import React from 'react';
import { Play, Pause, Rewind, FastForward } from 'lucide-react';

interface ControlsProps {
  isPlaying: boolean;
  onPlayPause: () => void;
  onRewind: () => void;
  onForward: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  isPlaying,
  onPlayPause,
  onRewind,
  onForward
}) => {
  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={onRewind}
        className="p-1 hover:-translate-y-[1px] transition-transform"
        aria-label="Rewind 10 seconds"
      >
        <Rewind className="w-4 h-4 text-white" />
      </button>
      <button
        onClick={onPlayPause}
        className="p-1 hover:-translate-y-[1px] transition-transform"
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        {isPlaying ? (
          <Pause className="w-5 h-5 text-white" />
        ) : (
          <Play className="w-5 h-5 text-white" />
        )}
      </button>
      <button
        onClick={onForward}
        className="p-1 hover:-translate-y-[1px] transition-transform"
        aria-label="Forward 10 seconds"
      >
        <FastForward className="w-4 h-4 text-white" />
      </button>
    </div>
  );
};

export default Controls;