import React from 'react';
import Controls from './Controls';
import VolumeControl from './VolumeControl';
import PlaybackSpeedControl from './PlaybackSpeedControl';
import VideoSwitch from './VideoSwitch';
import TimeDisplay from './TimeDisplay';
import FullscreenButton from './FullscreenButton';

interface MobileControlsProps {
  isPlaying: boolean;
  onPlayPause: () => void;
  onRewind: () => void;
  onForward: () => void;
  volume: number;
  onVolumeChange: (volume: number) => void;
  speed: number;
  onSpeedChange: (speed: number) => void;
  isFullEpisode: boolean;
  onToggle: () => void;
  currentTime: number;
  duration: number;
  onFullscreen: () => void;
}

const MobileControls: React.FC<MobileControlsProps> = ({
  isPlaying,
  onPlayPause,
  onRewind,
  onForward,
  volume,
  onVolumeChange,
  speed,
  onSpeedChange,
  isFullEpisode,
  onToggle,
  currentTime,
  duration,
  onFullscreen,
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex justify-between items-center">
        <VolumeControl volume={volume} onVolumeChange={onVolumeChange} />
        <Controls
          isPlaying={isPlaying}
          onPlayPause={onPlayPause}
          onRewind={onRewind}
          onForward={onForward}
        />
        <PlaybackSpeedControl speed={speed} onSpeedChange={onSpeedChange} />
      </div>
      <div className="flex justify-between items-center text-xs">
        <VideoSwitch isFullEpisode={isFullEpisode} onToggle={onToggle} />
        <TimeDisplay currentTime={currentTime} duration={duration} />
        <FullscreenButton onClick={onFullscreen} />
      </div>
    </div>
  );
};

export default MobileControls;
